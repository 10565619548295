.cta {

	.section-inner {
		padding-top: $cta--padding-t__mobile;
		padding-bottom: $cta--padding-b__mobile;
	}

	.cta-inner {
		padding-left: $cta--padding-h__mobile;
		padding-right: $cta--padding-h__mobile;
		background-color: get-color(light, 1);
	}
}

.cta-slogan {
	margin-bottom: $cta-slogan--padding-v__mobile;
}

.cta-action {
	color: get-color(primary, 1);

	.cta-button-text {
		font-size: 18px;
		font-weight: 500;
	}
}

@include media( '>medium' ) {

	.cta-split {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.cta-slogan {
		margin-bottom: $cta-slogan--padding-v__desktop;

		.cta-split & {
			margin-bottom: 0;
			margin-right: $cta-slogan--padding-h__desktop;
		}

		div {
			font-size: large;
		}
	}
}

@include media( '>=large' ) {
	.cta {

		.section-inner {
			padding-top: $cta--padding-t__desktop;
			padding-bottom: $cta--padding-b__desktop;
		}

		.cta-inner {
			padding-left: $cta--padding-h__desktop;
			padding-right: $cta--padding-h__desktop;
		}
	}
}