.features-split {

	.section-inner {
		padding-top: $features-split--padding-t__mobile;
		padding-bottom: $features-split--padding-b__mobile;

		.banner {
			padding-bottom: $split-item--padding-v__mobile / 2;
			// margin-bottom: $split-item--padding-v__mobile / 2;
			// margin-top: -$features-split-header--padding__desktop + 40px;
		}
	}

	.section-header {
		padding-bottom: $features-split-header--padding__mobile;
	}
}

@include media( '<=medium' ) {

  .banner-desktop {
    display: none;
  }

  .banner-mobile {
    display: block;
  }
}

@include media( '>medium' ) {

  .banner-desktop {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .banner-mobile {
    display: none;
  }


	.features-split {

		.section-inner {
			padding-top: $features-split--padding-t__desktop;
			padding-bottom: $features-split--padding-b__desktop;

			.banner {
				padding-bottom: 40px;
				// margin-bottom: 40px;
				margin-top: -$features-split-header--padding__desktop + 40px;
			}
		}

		.section-header {
			padding-bottom: $features-split-header--padding__desktop;
		}
	}
}
