// Features split (core/sections/_features-split.scss) ---------
$features-split--padding-t__mobile: 		0;		// features split, top padding (mobile)
$features-split--padding-b__mobile: 		null;		// features split, bottom padding (mobile)
$features-split--padding-t__desktop: 		0px;		// features split, top padding (desktop)
$features-split--padding-b__desktop: 		null;		// features split, bottom padding (desktop)
$features-split-header--padding__mobile: 	null;		// features split header, bottom padding (mobile)
$features-split-header--padding__desktop: 	92px;		// features split header, bottom padding (desktop)

$features-split-contact--padding-t__mobile: 		80px;
$features-split-contact--padding-t__desktop: 		130px;
$features-split-contact--padding-b__mobile: 		null;