.testimonial {

	.section-inner {
		padding-top: $testimonial--padding-t__mobile;
		padding-bottom: $testimonial--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $testimonial-header--padding__mobile;
	}

	.tiles-wrap {
		@if ( $testimonial-items--padding__mobile != null ) {
			margin-right: -($testimonial-items--padding__mobile / 2);
			margin-left: -($testimonial-items--padding__mobile / 2);
			margin-top: -($testimonial-items--padding__mobile / 2);

			&:last-of-type {
				margin-bottom: -($testimonial-items--padding__mobile / 2);
			}

			&:not(:last-of-type) {
				margin-bottom: ($testimonial-items--padding__mobile / 2);
			}
		}

		&.push-left {

			&::after {
				flex-basis: $testimonial-item--width;
				max-width: $testimonial-item--width;
				@if ( $testimonial-items--padding__mobile != null ) {
					padding-left: $testimonial-items--padding__mobile / 2;
					padding-right: $testimonial-items--padding__mobile / 2;
				}
			}
		}
	}

	.tiles-item {
		flex-basis: $testimonial-item--width;
		max-width: $testimonial-item--width;
		@if ( $testimonial-items--padding__mobile != null ) {
			padding: $testimonial-items--padding__mobile / 2;
		}
	}

	.tiles-item-inner {
		padding-top: $testimonial-item--inner-padding-v;
		padding-bottom: $testimonial-item--inner-padding-v;
		padding-left: $testimonial-item--inner-padding-h;
		padding-right: $testimonial-item--inner-padding-h;
	}
}

.testimonial-item-content {
	flex-grow: 1;
}

.testimonial-item-source {
	display: inline-flex; // to allow centering when using .content-center on a parent tag
	flex-wrap: wrap;
	align-items: center;
}

.testimonial {

	.tiles-item-inner {
		background: get-color(light, 1);		
	}
}

.testimonial-item-content {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 24px;
        height: 18px;
        margin-top: 12px;
        margin-bottom: 16px;
        background-image: inline-svg('<svg width="24" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M0 13.481c0-2.34.611-4.761 1.833-7.263C3.056 3.716 4.733 1.643 6.865 0L11 2.689C9.726 4.382 8.777 6.093 8.152 7.824c-.624 1.73-.936 3.578-.936 5.545V18H0v-4.519zm13 0c0-2.34.611-4.761 1.833-7.263 1.223-2.502 2.9-4.575 5.032-6.218L24 2.689c-1.274 1.693-2.223 3.404-2.848 5.135-.624 1.73-.936 3.578-.936 5.545V18H13v-4.519z" fill="' + get-color(primary, 1) + '" fill-rule="nonzero"/></svg>');
        background-repeat: no-repeat;
    }		
}

.testimonial-item-footer {
    font-weight: 600;
    padding-top: 20px;

    &.has-top-divider {

        &::before {
            background: get-color(dark, 3);
        }
    }
}

.testimonial-item-link {
	color: get-color(primary, 2);

	a {
        color: get-color(secondary, 3);
        text-decoration: none;

        &:hover {
            color: get-color(secondary, 2);
        }
	}
}

@include media( '>medium' ) {

	.testimonial {

		.section-inner {
			padding-top: $testimonial--padding-t__desktop;
			padding-bottom: $testimonial--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $testimonial-header--padding__desktop;
		}

		@if ( $testimonial-items--padding__desktop != null ) {

			.tiles-wrap {
				margin-right: -($testimonial-items--padding__desktop / 2);
				margin-left: -($testimonial-items--padding__desktop / 2);
				margin-top: -($testimonial-items--padding__desktop / 2);

				&:last-of-type {
					margin-bottom: -($testimonial-items--padding__desktop / 2);
				}

				&:not(:last-of-type) {
					margin-bottom: ($testimonial-items--padding__desktop / 2);
				}

				&.push-left {

					&::after {
						padding-left: $testimonial-items--padding__desktop / 2;
						padding-right: $testimonial-items--padding__desktop / 2;
					}
				}
			}

			.tiles-item {
				padding: $testimonial-items--padding__desktop / 2;
			}
		}
	}
}
