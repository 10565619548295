.features-split-aboutus {
	
	.section-inner {
		padding-top: $features-split-contact--padding-t__mobile;
		padding-bottom: $features-split--padding-b__mobile;
	}
}

@include media( '>medium' ) {
	.features-split-aboutus {
	
		.section-inner {
			padding-top: $features-split-contact--padding-t__desktop;
		}
	}
}