.button {
    display: inline-flex;
	@include font-size(button);
    @include font-weight(button);
	padding: (($button-form--height - get-line-height(button) - ($button-form--border-width * 2)) / 2) ($button--padding-h - $button-form--border-width);
	height: $button-form--height;
    text-decoration: none !important;
	text-transform: $button--transform;
	color: color(button-light);
    background-color: color-bg(button-light);
    border-width: $button-form--border-width;
	border-style: $button-form--border-style;
	border-color: color-border(button-light);
    border-radius: $button--radius;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    letter-spacing: inherit;
    white-space: nowrap;
		transition: background .15s ease;

    &:active {
        outline: 0;
    }

	&:hover {
		background-color: color-bg(button-light-hover);
		border-color: color-border(button-light-hover);
	}

	&.is-loading {
		position: relative;
		color: transparent!important;
		pointer-events: none;

		&::after {
			content: '';
			display: block;
			position: absolute;
			width: get-line-height(button);
			height: get-line-height(button);
			margin-left: -(get-line-height(button) / 2);
			margin-top: -(get-line-height(button) / 2);
			top: 50%;
			left: 50%;
			border: $button-loading--thickness solid color(button-light);
			border-radius: 50%;
			border-right-color: transparent !important;
			border-top-color: transparent !important;
			z-index: 1;
			animation: button-loading .6s infinite linear;
		}
	}

	&[disabled] {
		cursor: not-allowed;
		color: color(button-disabled);
		background-color: color-bg(button-disabled) !important;
		border-color: color-border(button-disabled);

		&.is-loading {

			&::after {
				border-color: color(button-disabled);
			}
		}
	}
}

.button-sm {
    padding: (($button-form-sm--height - get-line-height(button) - ($button-form--border-width * 2)) / 2) ($button-sm--padding-h - $button-form--border-width);
	height: $button-form-sm--height;
}

.button-md {
	padding: (($button-form-md--height - get-line-height(button) - ($button-form--border-width * 2)) / 2) ($button-md--padding-h - $button-form--border-width);
height: $button-form-md--height;
}

.button-dark {
	color: color(button-dark);
    background-color: color-bg(button-dark);
	border-color: color-border(button-dark);

    &:hover {
        background-color: color-bg(button-dark-hover);
		border-color: color-border(button-dark-hover);
    }

	&.is-loading {

		&::after {
			border-color: color(button-dark);
		}
	}
}

.button-primary {
	color: color(button-primary);
    background-color: color-bg(button-primary);
	border-color: color-border(button-primary);

    &:hover {
        background-color: color-bg(button-primary-hover);
		border-color: color-border(button-primary-hover);
    }

	&.is-loading {

		&::after {
			border-color: color(button-primary);
		}
	}
}

.button-secondary {
	color: color(button-secondary);
    background-color: color-bg(button-secondary);
	border-color: color-border(button-secondary);

    &:hover {
        background-color: color-bg(button-secondary-hover);
		border-color: color-border(button-secondary-hover);
    }

	&.is-loading {

		&::after {
			border-color: color(button-secondary);
		}
	}
}

.button-red {
	color: color(button-primary);
    background-color: red;
	border-color: color-border(button-primary);

    &:hover {
        background-color: lighten(red, 15%);
		border-color: color-border(button-primary-hover);
    }

	&.is-loading {

		&::after {
			border-color: color(button-primary);
		}
	}
}

.button-block {
    display: flex;
	width: 100%;
}

.button-group {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-right: -($button-group-padding / 2);
	margin-left: -($button-group-padding / 2);
	margin-top: -($button-group-padding / 2);

	&:last-of-type {
		margin-bottom: -($button-group-padding / 2);
	}

	&:not(:last-of-type) {
		margin-bottom: ($button-group-padding / 2);
	}

	> [class*=button] {
		margin: $button-group-padding / 2;
	}
}

.button-selector {
	&:hover {
		background-color: color-bg(button-light);
	}
}

.hide-box {
	background-color: color-bg(button-transparent);

	&:hover {
		background-color: color-bg(button-light);
	}
}

.button-text-hover {
	color: color(header-link);
	transition: .2s ease;
	transition-delay: 0.05s;	// Adds transition to link color change on hover
	
	&:hover,
	&:active {
		color: color(header-link-hover);
	}
}

.button-link-color {
	color: color(header-link);
}

.button-cancel {
	color: get-color(light,3);
    background-color: color-bg(button-light);
	border-color: color-border(button-light);
  margin-left: -8px !important;

    &:hover {
        background-color: color-bg(button-light-hover);
		border-color: color-border(button-light-hover);
    }

	&.is-loading {

		&::after {
			border-color: color(button-light);
		}
	}
}

// .button-box-hover {
// 	transition: .2s ease;
// 	transition-delay: 0.05s;

// 	&:hover,
// 	&:active {
// 		// background-color: color-bg();
// 	}
// }

.scroll-top-button {
	position: relative;

	.up-arrow-icon {
		position: fixed;
		bottom: 30px;
		right: 20px;
		z-index: 20;
		background-color: lighten(get-color(light, 2), 25%);
		color: get-color(primary,1);
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 1;
		font-size: 30px;
		// transition: all .5s ease-in-out;

		// &:hover {
			// animation: movebtn 2s ease-in-out infinite;
		// }
	}
	cursor: pointer;
}

@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(-10px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(-10px);
  }
  100%{
    transform: translateY(0px);
  }
}

@include media( '<=medium' ) {

	.button-wide-mobile {
		width: 100%;
		max-width: $button--max-size-mobile;
	}
}

@keyframes button-loading {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
