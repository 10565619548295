.swiper {

	.section-inner {
		padding-top: $swiper--padding-t__mobile;
		padding-bottom: $swiper--padding-b__mobile;
	}
}

.swiper-inner {

	// Affects only swiper full
	> .swiper-content + .swiper-figure,
	> .swiper-figure + .swiper-content {
		margin-top: $swiper--inner-padding-v_mobile;
	}

	> .swiper-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

// Arrows styling
.slide-arrow{
  position: absolute;
  display: block;
  z-index: 10;
  top: calc(50% - 45px - 10vw - 16px);
  line-height: 1;
  // margin-top: -2em;
  cursor:pointer;
	user-select: none;
		-moz-user-select: none;
		-khtml-user-select: none;
		-webkit-user-select: none;
		-o-user-select: none;
	padding: 10vw 1vw 10vw 1vw;
	transition: opacity .4s ease-in-out;
  	-moz-transition: opacity .4s ease-in-out;
    -webkit-transition: opacity .4s ease-in-out;
  &:hover{
    opacity:.6;
		// transition: all 0.3s ease-in;
  }
  &.swiper-button-disabled{
    display: none;
  }
  &__prev{
		z-index: 15;
    left: -1vw;
  }
  &__next{
		z-index: 15;
    right: -1vw;
  }
  .material-icons {
    font-size: 80px;
    color:#fff;
    text-shadow: 0 0 5px #333;
    line-height: 1;
  }
}

// Pagination styling
.swiper-pagination1 {
	position: relative;
	padding-top: 12px;
	right: -46.3%;
	width: auto !important;
	left: auto !important;
	margin: 0;
	margin-bottom: -30px;
	z-index: 15;
}

// Pagination styling
.swiper-pagination2 {
	position: relative;
	padding-top: 12px;
	right: -69.5%;
	width: auto !important;
	left: auto !important;
	margin: 0;
	margin-bottom: -30px;
	z-index: 15;
  top: -50px;
}

.swiper-pagination-bullet {
	/* padding: 5px 10px; */
  margin: 20px auto;
	border-radius: 0;
	width: 15px;
	height: 15px;
	color:#000;
	opacity: 1;
	background: rgba(0,0,0,0.2);
	cursor:pointer;
}

.swiper-pagination-bullet-active {
	color:#fff;
	// background: #007aff;
	background: get-color(primary,1);
  transform: rotate(45deg);
  transition: all 0.4s ease-in-out 0.1s;
}

@include media( '<=medium' ) {

	.swiper {
		
		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $swiper--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $swiper--inner-padding-v_mobile;
					}
				}
			}
		}
	}

	.slide-arrow {
		visibility: hidden;
	}

	.swiper-pagination1 {
		right: -39.2%;
	}
  .swiper-pagination2 {
    top: -10px;
    right: -30%;
  }
}

@include media( '<=small' ) {
	.swiper-pagination2 {
		right: -22%;
	}
}

@include media( '>medium' ) {

	.swiper {

		.section-inner {
			padding-top: $swiper--padding-t__desktop;
			padding-bottom: $swiper--padding-b__desktop;
		}
	}

	.swiper-inner {

		// Affects only swiper full
		> .swiper-content + .swiper-figure,
		> .swiper-figure + .swiper-content {
			margin-top: $swiper--inner-padding-v_desktop;
		}
	}

	.slide-arrow {
		visibility: hidden;
		opacity: 0;
	}

	.swiper-figure:hover .slide-arrow {
		visibility: visible;
		opacity: 1;
		&:hover {
			opacity: 0.6 !important;
		}
	}

	.swiper-pagination1 {
		right: -43%;
	}
  .swiper-pagination2 {
    right: -68%;
		top: -10px;
  }
}

@include media('>=large') { 
	.swiper-pagination1 {
		right: -46.3%;
	}
  .swiper-pagination2 {
    // right: -55%;
		right: -69%;
  }
}

@media(min-width: 1100px) and (max-width: 1300px) {
	.swiper-pagination2 {
		right: auto;
		margin-left: 8px;
	}
}

@media(min-width: 820px) and (max-width: 1100px) {
	.swiper-pagination2 {
		right: -54%;
	}
}

@include media( '<large') {
	.swiper {
		width: 100vw;
		position: relative;
		left: calc(-50vw + 50%);
	}
}