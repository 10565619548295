// CSS styling for the gallery component
.indoor-gallery {
  
  .gallery-image {
    cursor: pointer;
    margin: auto;
    // width: 100% !important;
    // height: auto !important;
  }

  margin-right: -32px;
  
  // .image-container {
  //   margin-bottom: 30px
  // }

  // .placeholderdiv {
  //   background-color: rgb(0, 0, 0);
  //   text-align: center;
  //   width: 400px;
  //   height: 300px;
  //   color:aqua;
  // }
}

.admin-gallery {

  .image-container {
    position: relative;

    .gallery-image {
      cursor: pointer;
      margin: auto;
    }
  }
}

// Styling for the gallery section
.gallery-section {
  .container {
    .tags-box {
      padding-top: $gallery-tags--padding-t__mobile;
      padding-bottom: $gallery-tags--padding-b__mobile;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  .section-inner {
    padding-top: $gallery-section--padding-t__mobile;
    padding-bottom: $gallery-section--padding-b__mobile;
  }
}

.pswp__caption__center {
  text-align: center !important;
}

@include media( '>medium' ) {
  // Styling for the gallery section on desktop
  .gallery-section {
    .tags-box {
      padding-top: $gallery-tags--padding-t__desktop;
      padding-bottom: $gallery-tags--padding-b__desktop;
    }

    .gallery-content {
      padding-top: $gallery-content--padding-t__desktop;
      padding-bottom: $gallery-content--padding-b__desktop;
    }
  }

  
  .admin-gallery {
    .delete-forever {
      font-size: 60px;
      color:red;
      text-shadow: 0 0 2px #333;
      line-height: 1;
      cursor: pointer;
      position: absolute;   // parent needs position: relative
      top:0; right:0;

      &:hover {
        text-shadow: 0 0 8px black;
      }
    }
  }

  // Photoswipe styling for enlarged images
  .pswp__button.pswp__button--arrow--left {
    // padding-left: 30px;
    padding-right: 240px;
    // padding-bottom: 400px;
    padding-top: 140px;
    // margin-top: -150px;
  }
  // .pswp__button--arrow--left::before {
  //   margin-top: 100px;
  // }
  .pswp__button.pswp__button--arrow--right {
    // padding-left: 30px;
    padding-left: 240px;
    // padding-bottom: 100px;
    padding-top: 140px;
    // margin-top: -150px;
  }
  // .pswp__button--arrow--right::before {
  //   margin-top: 100px;
  // }

}

@include media( '<=large' ) {
  .gallery-section {
    .tags-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center !important;
      .button {
        margin: 5px 10px 5px 0px;
        border-radius: 0px;
      }
      .button-selector {
        background-color: color-bg(button-primary);
        color: color(button-primary);
        border-color: color-border(button-primary);
        &:hover {
          background-color: color-bg(button-primary);
        border-color: color-border(button-primary);
        }
      }
      
      .hide-box {
        background-color: color-bg(button-light);
        color: color(button-light);
        border-color: color-border(button-light);

      
        &:hover {
          background-color: color-bg(button-primary);
          color: color(button-primary);
        }
      }
    }

    .gallery-content {
      padding-top: $gallery-content--padding-t__desktop;
      padding-bottom: $gallery-content--padding-b__desktop;
    }
  }
}