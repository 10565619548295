.features-split-contact {
	
	.section-inner {
		padding-top: $features-split-contact--padding-t__mobile;
		padding-bottom: $features-split--padding-b__mobile;
	}

  .recaptcha-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
  }

  .send-button {
    margin-top: 14px;
    margin-bottom: 14px;

  }

  .mail-response {
    font-size: 85%;
  }

  .mobile-remove-contact {
    display: block;
  }

}


svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 2px;
}

#email-icon {
  margin-right: 6px;
}


@include media( '<=medium' ) {

  .desktop-tel {
    display: none;
  }

  .mobile-tel {
    display: block;
  }
}

@include media( '>medium' ) {

  .desktop-tel {
    display: block;
  }
  
  .mobile-tel {
    display: none;
  }

  .features-split-contact {
    
    .section-inner {
      padding-top: $features-split-contact--padding-t__desktop;
    }
  }
}

@include media( '<large' ) {
  .mobile-remove-contact {
    display: none !important;
  }
}