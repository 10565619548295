.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}

.tag-selector {
  .form-checkbox {
    margin-right: 10px;
    margin-left: 10px;
    padding-left: 22px;
  }
}

.errmsg {
  color: red;
}

.logout-button {
  position: fixed;
		top: 40px;
		right: 20px;
		z-index: 21;
		text-align: center;
		line-height: 1;
		font-size: 30px;
}