.map-section {

	.section-inner {
		padding-top: $map-section--padding-t__mobile;
		padding-bottom: $map-section--padding-b__mobile;
	}

	.section-header {
		padding-top: $map-section-header--padding-t_mobile;
		padding-bottom: $map-section-header--padding-b_mobile;
	}
}

.map-section-inner {

	.map-content {
		position: relative;
		padding-bottom: 38%; // Equal to aspect ratio of map 
		height: 0;
		overflow: hidden;
	}

	.map-content iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@include media( '<=medium' ) {

	.map-section {

		.map-content iframe {
			height: 100%;
		}

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $map-section--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $map-section--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.map-section {

		.section-inner {
			padding-top: $map-section--padding-t__desktop;
			padding-bottom: $map-section--padding-b__desktop;
		}
	}

	.map-section-inner {

		// Affects only map-section full
		> .map-section-content + .map-section-figure,
		> .map-section-figure + .map-section-content {
			margin-top: $map-section--inner-padding-v_desktop;
		}
	}
}
