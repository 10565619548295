/* Fixed Background */
.parallax {
  /* The image used */
  background-image: url("../../../images/home/peter-cnc.jpg");

  /* Set a specific height */
  min-height: 500px;

  /* Create parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(35%);
}

.fixed-container {
  min-height: 500px;
  margin-top: -500px;

  .emptywindow {
    position: absolute;
    top: 300%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    text-align: center;
  }

  .h3 {
    color: white;
    line-height: 55px;
    font-size: 40px;
  }
}


//Turn off parallax scrolling for all tablets and phones.
@include media( '<=medium' ) {
  .parallax {
    background-attachment: scroll;
    min-height: 300px;
  }

  .fixed-container {
    min-height: 300px;
    margin-top: -300px;

    .h3 {
      line-height: 40px;
      height: 50%;
      font-size: 30px;
    }

    .emptywindow {
      width: 80%;
    }
  }
}