// Gallery (core/sections/_gallery.scss) ---------
$gallery-tags--padding-t__mobile: 		null;
$gallery-tags--padding-b__mobile:			null;
$gallery-tags--padding-t__desktop:    null;
$gallery-tags--padding-b__desktop:    6px;
$gallery-section--padding-t__mobile: 		null;
$gallery-section--padding-b__mobile:			48px;
$gallery-section--padding-t__desktop: 		null;
$gallery-section--padding-b__desktop: 		null;
$gallery-section-header--padding__mobile: 	null;
$gallery-content--padding-t__desktop:     24px;
$gallery-content--padding-b__desktop:     null;
$gallery-content--padding-t__mobile:     24px;
$gallery-content--padding-b__mobile:     null;
// $features-tiles-header--padding__desktop: 	null;
// $features-tiles-items--padding__mobile: 	32px;
// $features-tiles-items--padding__desktop: 	64px;
// $features-tiles-item--width: 				317px;
